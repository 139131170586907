<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px" class="pt-3">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa-solid fa-users-viewfinder" aria-hidden="true" style="font-size: 21px; margin-right: 10px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
            <div v-if="orgName">
              <h5>University Name: {{orgName}}<br></h5>
            </div>
          </template>
            <template v-slot:body >
              <div class="row w-100 mb-3 multiple_boxes_row ">
            <div class="ml-2" v-for="(value, key, index) in organisationFollowerCountObj" :key="key+value">
              <div class="boxs">
                <iq-card class="btn mb-1 multiCardboxs" :style="'background:'+getBgFor(index)">
                  <span  class="textintworow text-white">{{key}} </span><br/>
                  <span class="badge badge-light">{{organisationFollowerCount[value]}}</span>
                </iq-card>
              </div>
            </div>
          </div>
              <b-row >
                <b-col xs="12" sm="5" md="4" lg="4" xl="4" class="mb-3">
                  <b-form-input v-model="filter" type="search" :placeholder="cvSearchText"></b-form-input>
                </b-col>
                <b-col sm="4" md="3" lg="3" xl="3" class="mb-2">
                <select v-model="whereFilter.class" class="form-control" @change="analyticsFollowedList()">
                  <option value="12">12</option>
                  <option value="11">11</option>
                  <option value="10">10</option>
                  <option value="9">9</option>
                  <option value="8">8</option>
                  <option value="7">7</option>
                </select>
              </b-col>
                <b-col xs="12" sm="3" md="2" lg="3" xl="2" v-if="analyticsFollowerObj && analyticsFollowerObj.length">
                <VueJsonToCsv :json-data="analyticsFollowerObj" class="pull-right mb-2" csv-title="followerDocument" >
                  <b-btn variant="primary" class="mt-1"> Download
                  </b-btn>
                </VueJsonToCsv>
              </b-col>
              <b-col xs="12" sm="12" md="3" lg="2" xl="3">
              <div class="dateRange">
                <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="analyticsFollowerListDateWise()" ></date-range-picker>
              </div>
            </b-col>
              </b-row>
              <b-row>
                <b-col class="container_minheight" md="12" v-if="analyticsFollowerObj && analyticsFollowerObj.length > 0">
                  <b-table
                hover
                responsive
                :items="analyticsFollowerObj"
                :fields="columns"
                :no-border-collapse="true"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                :current-page="currentPage"
                :per-page="perPage">
                   <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                    </template>
                    <template v-slot:cell(user_name)="data">
                      <span class="pointer" @click="openUserProfile(data.item.user_id)">{{ data.item.user_name }}</span><br>
                      <span><small>
                        Class&nbsp;{{ data.item.class }}<br>
                        {{ data.item.school_name }}<br>
                        {{data.item.school_city ? data.item.school_city : '' }}
                        {{data.item.school_state ? ', ' + school.item.school_state : '' }}
                        {{data.item.school_country ? ', ' + data.item.school_country : '' }}<br>
                      </small></span>
                    </template>
                    <template v-slot:cell(sch_city)="data">
                      <p v-html="data.item.sch_city" class="text-wrap w-100px">
                      </p>
                    </template>
                    <template v-slot:cell(sch_country)="data">
                      <p v-html="data.item.sch_country" class="text-wrap w-100px">
                      </p>
                    </template>
                  </b-table>
                </b-col>
                <b-col class="container_minheight" md="12" v-else>
                  No data found
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="7" md="8" class="my-1">
                </b-col>
                <b-col sm="5" md="4" class="my-1">
                  <b-pagination v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0">
                </b-pagination>
                </b-col>
              </b-row>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
.dateRange {
  float: right;
}
</style>

<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css"
import moment from "moment"
import { analyticsFollower } from "../../../../FackApi/api/analytics/analytics_follower"
import { socialvue } from "../../../../config/pluginInit"
import ColorCodes from "../../../../Utils/colorcodes.js"
import userPermission from "../../../../Utils/user_permission"

export default {
  name: "analyticsFollowerListReport",
  data () {
    return {
      apiName: "analytics_followed_list",
      cvCardTitle: "Follower Report",
      analyticsFollowerObj: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      sortBy: "name",
      sortDesc: false,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 14,
      cvSearchText: "Type to Search",
      columns: [],
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        class: ""
      },
      orgId: "",
      organisationFollowerCountObj: { // Key value pair where key is label and value will use to get data from api
        "Followers Count": "follow_count"
      },
      organisationFollowerCount: "",
      orgName: ""
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async mounted () {
    this.orgId = this.$route.params.orgId
    socialvue.index()
    await this.getDateFilter()
    this.analyticsFollowedList()

    this.cvCardTitle = "Follower Report"
    let self = this

    switch (this.userData.user_role) {
      case "USERROLE11111":
      case "USERROLE11118":
        self.columns = [
          { label: "# ", key: "index", class: "text-left align-text-top text-wrap", sortable: true },
          { label: "Name", key: "user_name", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Email", key: "user_email", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Mobile", key: "user_mobile", class: "text-left align-text-top text-wrap ", sortable: true }
        ]
        break
      case "USERROLE11115":
      case "USERROLE11116":
        self.columns = [
          { label: "# ", key: "index", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Email", key: "user_email", class: "text-left align-text-top text-wrap ", sortable: true },
          { label: "Mobile", key: "user_mobile", class: "text-left align-text-top text-wrap ", sortable: true }
        ]
        break
      default:
        break
    }
  },
  methods: {
  /**
   * getBgFor
   */
    getBgFor (index) {
      return ColorCodes.getBgColor(index)
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
   * calling analyticsFollowerListDateWise function on date wise and storing value in store
   */
    async analyticsFollowerListDateWise () {
      this.analyticsFollowedList()
      await this.setDateFilter()
    },
    /**
     * analyticsFollowedList
     * Current Implementation is only for UNIV
  */
    async analyticsFollowedList () {
      try {
        let orgObj = {}
        if (this.userData.user_role === "USERROLE11115" &&
          this.userData.modules.organisations && Object.keys(this.userData.modules.organisations).length > 0
        ) {
          // A university admin can be associated with multiple universities, check if the university he is trying to view the follower list of, is present in its moduke or not, in case, the university is not in its modules, redirect the user back to the previous screen
          let isUnivAdminAllowedToViewFollowers = Object.keys(this.userData.modules.organisations).find(orgId => orgId == this.orgId)
          if (isUnivAdminAllowedToViewFollowers) {
            orgObj.module_obj_id = isUnivAdminAllowedToViewFollowers
            orgObj.module_name = "UNIV"
          }
          else {
            this.$router.back()
          }
        }
        else {
          orgObj.module_obj_id = this.orgId
          orgObj.module_name = "UNIV"
        }

        let orgListResp = await analyticsFollower.analyticsFollowedList(this, orgObj, this.whereFilter)
        if (orgListResp.resp_status) {
          this.analyticsFollowerObj = orgListResp.resp_data.data
          this.organisationFollowerCount = orgListResp.followerCount
          this.orgName = this.analyticsFollowerObj[0].org_name
          this.totalRows = orgListResp.resp_data.count
        }
        else {
          this.toastMsg = orgListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at analyticsFollowedList() and Exception:", err.message)
      }
    },
    /**
       * getFormattedDateTime
       */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    }
  }
}
</script>
